import {Enum} from "xb-lib-view/dist/utils";

export class PeriodEnum extends Enum {
  static a = 1;
  static b = 2;
  static c = 3;
  static d = 4;
  static e = 5;
  static f = 6;
  static g = 7;
  static h = 8;
  static i = 9;
  static j = 10;
  static k = 11;
  static l = 12;
  static m = 13;
  static n = 14;
  static o = 15;
  static p = 16;
  static q = 17;
  static r = 18;
  static s = 19;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(PeriodEnum.a, '一月');
    this.add(PeriodEnum.b, '二月');
    this.add(PeriodEnum.c, '三月');
    this.add(PeriodEnum.d, '四月');
    this.add(PeriodEnum.e, '五月');
    this.add(PeriodEnum.f, '六月');
    this.add(PeriodEnum.g, '七月');
    this.add(PeriodEnum.h, '八月');
    this.add(PeriodEnum.i, '九月');
    this.add(PeriodEnum.j, '十月');
    this.add(PeriodEnum.k, '十一月');
    this.add(PeriodEnum.l, '十二月');
    this.add(PeriodEnum.m, '第一季度');
    this.add(PeriodEnum.n, '第二季度');
    this.add(PeriodEnum.o, '第三季度');
    this.add(PeriodEnum.p, '第四季度');
    this.add(PeriodEnum.q, '上半年度');
    this.add(PeriodEnum.r, '下半年度');
    this.add(PeriodEnum.s, '全年');
  }
}