import { shopRedList, shopBlackList, getPeriodByYear } from '@/api/projects/zhjg'
import moment from 'moment'
import { PeriodEnum } from '../enum'

export default {
    props: {
        date: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            form_data: {
                year: moment(this.date),
                period: moment(this.date).format('M') * 1,
            },
            periodList: [],
            yearShowOne: false,
            shop: {
                redList: [],
                columns: [
                    {
                        title: '商铺名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '路段',
                        dataIndex: 'streetName',
                        key: 'streetName',
                    },
                    {
                        title: '评分',
                        key: 'score',
                        dataIndex: 'score',
                    },
                    {
                        title: '星级',
                        key: 'starLevel',
                        dataIndex: 'starLevel',
                        scopedSlots: { customRender: 'starLevel' },
                    },
                ],
                blackList: [],
            }
        }
    },
    created() {
        this.req()
        this.getPeriodList()
    },
    methods: {
        req() {
            this.PUqiZld.content = `${PeriodEnum.getValueByIndex(this.form_data.period)} · 红榜名单`
            this.PiKVryd.content = `${PeriodEnum.getValueByIndex(this.form_data.period)} · 黑榜名单`
            shopRedList({
                year: this.form_data.year?.format('YYYY'),
                period: this.form_data.period
            }).then(res => {
                if (res.ok) {
                    this.shop.redList = res.list
                }
            })
            shopBlackList({
                year: this.form_data.year?.format('YYYY'),
                period: this.form_data.period
            }).then(res => {
                if (res.ok) {
                    this.shop.blackList = res.list
                }
            })
        },
        getPeriodList() {
            getPeriodByYear({
                year: this.form_data.year?.format('YYYY')
            }).then(res => {
                if (res.ok) {
                    this.periodList = res.list.sort((a, b) => a - b).map(id => {
                        return PeriodEnum.getValues().find(el => el.id === id)
                    })
                    if (!this.periodList.find(el => el.id == this.form_data.period)) {
                        this.form_data.period = this.periodList[0]?.id
                    }
                    this.req()
                }
            })
        },
        onExport(type) {
            const date = moment(this.date)
            if (type == 'red') {
                shopRedList({
                    isExport: true,
                    period: date.format('M'),
                    year: date.format('YYYY')
                }).then(res => {
                    if (res.ok) {
                        this.$message.success("数据导出成功，请稍后在右上角通知中心查看下载！");
                    }
                })
            } else {
                shopBlackList({
                    isExport: true,
                    period: date.format('M'),
                    year: date.format('YYYY')
                }).then(res => {
                    if (res.ok) {
                        this.$message.success("数据导出成功，请稍后在右上角通知中心查看下载！");
                    }
                })
            }
        },
        onBack() {
            if (this.$router) {
                this.$router.back()
            } else {
                this.$parent.close()
            }
        },
        // 弹出日历和关闭日历的回调
        openChangeOne(status) {
            if (status) {
                this.yearShowOne = true
                setTimeout(() => {
                    document.onclick = () => {
                        this.yearShowOne = false
                        document.onclick = null
                    }
                }, 0);
            }
        },
        // 得到年份选择器的值
        panelChangeOne(value) {
            if (this.form_data.year != value) {
                this.form_data.year = value;
                // this.form_data.period = 1
                this.getPeriodList()
            }
            this.yearShowOne = false;
        },
        disabledEndDate(endValue) {
          const startValue = moment().endOf('Y');
          if (!endValue || !startValue) {
            return false;
          }
          return startValue.valueOf() < endValue.valueOf();
        },
    },
}