
import co286mytszlels41z3g0 from '@/cLibrary/project/海安市/co286mytszlels41z3g0/co286mytszlels41z3g0.vue';
import mixins from './service';

export default {
	name: 'hongheibangxiangqing-PJRG',
	mixins,
	data: function() {
		return {
			images: {
				rongqizujian_PRC51E9: `${window.baseUrl}oss-images/20230105/1672911526PZFr.png`,
				zuocebiaoti_PP3JSvG: `${window.baseUrl}oss-images/20230922/1695379525PLcG.png`,
				biaotiqu_PdBmtVL: `${window.baseUrl}oss-images/20240202/1706843682Pm8M.png`,
				biaogezhanwei_PyOXSgN: `${window.baseUrl}oss-images/20240202/1706843981PayG.png`,
				biaogequ_PTMV6WH: `${window.baseUrl}oss-images/20240202/1706843911PWKJ.png`,
				biaotiqu_P5uluCi: `${window.baseUrl}oss-images/20240202/1706844057PEq6.png`,
				biaogezhanwei_PeNnD0Z: `${window.baseUrl}oss-images/20240202/1706843981PayG.png`,
				biaogequ_P9N2pgg: `${window.baseUrl}oss-images/20240202/1706843911PWKJ.png`,
				genjiedian_PC8KBCc: `${window.baseUrl}oss-images/20240202/1706844464P6mU.jpg`,
			},
			P1EykbM: {
				"content": "红 黑 榜 详 情"
			},
			PUqiZld: {
				"content": "1月 · 红榜名单"
			},
			PbIr17Z: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PiKVryd: {
				"content": "1月 · 黑榜名单"
			},
			PvKuw4d: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
		}
	},
	methods: {
		getBackground(name) {
			return {
				backgroundImage: `url(${this.images[name]})`
			}
		},

	},
	components: {
		co286mytszlels41z3g0,
	},
}
